// Assuming you're working within the React App.js component,
// here's how you can add a "Remove" button for each todo item:

import React, { useState, useEffect } from 'react';
import './App.css';
function App() {
  const [todos, setTodos] = useState([]);
  const [newTodo, setNewTodo] = useState('');

  useEffect(() => {
    fetch('https://atom-tasker-worker.ramosmarjonf.workers.dev/')
      .then(response => response.json())
      .then(data => setTodos(data))
      .catch(error => console.error('Error fetching todos:', error));
  }, []);

  const handleAddTodo = () => {
    const todoItem = { id: Date.now(), text: newTodo }; // Adding an ID for each todo
    fetch('https://atom-tasker-worker.ramosmarjonf.workers.dev/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(todoItem),
    })
      .then(response => response.json())
      .then(data => {
        setTodos([...todos, data]);
        setNewTodo('');
      })
      .catch(error => console.error('Error adding todo:', error));
  };

  const handleRemoveTodo = (id) => {
    // Send a request to your worker to remove the todo item by id
    fetch(`https://atom-tasker-worker.ramosmarjonf.workers.dev/todo/${id}`, {
      method: 'DELETE',
    })
    .then(response => response.json())
    .then(() => {
      // Todo removed successfully from the backend, now update the local state
      const updatedTodos = todos.filter(todo => todo.id !== id);
      setTodos(updatedTodos);
    })
    .catch(error => console.error('Error removing todo:', error));
  };

  const handleInputChange = (event) => {
    setNewTodo(event.target.value);
  };
  const handleCheckboxChange = (id, itemIndex) => {
    // Assuming we have a state variable that holds the todos like so:
    // const [todos, setTodos] = useState([]);
  
    // We find the index of the todo item we want to update
    const todoIndex = todos.findIndex(todo => todo.id === id);
  
    // If the todo item exists
    if (todoIndex !== -1) {
      // We create a new copy of the todos
      let newTodos = [...todos];
  
      // We toggle the 'completed' state of the sub-item
      newTodos[todoIndex].items[itemIndex].completed = !newTodos[todoIndex].items[itemIndex].completed;
  
      // We set the new todos state
      setTodos(newTodos);
    }
  };
  // return (
  //   <div className="App">
  //     <div className="todo-input-container">
  //       <input
  //         type="text"
  //         value={newTodo}
  //         onChange={handleInputChange}
  //         className="todo-input"
  //         placeholder="Add a new todo..."
  //       />
  //       <button onClick={handleAddTodo} className="todo-button">Add Todo</button>
  //     </div>
  //     <ul className="todo-list">
  //       {todos.map((todo) => (
  //         <li key={todo.id} className="todo-item">
  //           <span>{todo.text}</span>
  //           <button onClick={() => handleRemoveTodo(todo.id)} className="remove-button">Remove</button>
  //         </li>
  //       ))}
  //     </ul>
  //   </div>
  // );

  return (
    <div className="list-wrapper">
      <div className="todo-input-container">
        <input
          type="text"
          value={newTodo}
          onChange={handleInputChange}
          className="todo-input"
          placeholder="Add a new todo..."
        />
        <button onClick={handleAddTodo} className="todo-button">Add Todo</button>
      </div>
      <ul className="styled-list">
        <h1>Todo</h1>
        <hr />
        {todos.map(todo => (
          <li key={todo.id}>
            <label>
              <input type="checkbox" name="checkbox" />
              <span className="todo-text">{todo.text}</span>
              <span className="todo-remove" onClick={() => handleRemoveTodo(todo.id)}>❌</span>
            </label>
            {todo.items && (
              <ul>
                {todo.items.map((item, index) => (
                  <li key={index}>
                    <label>
                      <input type="checkbox" name="checkbox" onChange={() => handleCheckboxChange(todo.id, index)} />
                      <span>{item}</span>
                    </label>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
     
    </div>
  );
}

export default App;

// Note: This example adds a simple "Remove" button next to each todo item.
// In a real-world application, you would need to ensure that the backend worker supports deleting individual todo items by ID.
// The commented-out fetch request within `handleRemoveTodo` function illustrates how you might send a DELETE request to your worker.
